import React, {ContextType, useEffect, useRef, useState} from 'react';
import {SafeAreaView} from "react-native-safe-area-context";
import {View, Text, StyleSheet, PanResponder, TouchableOpacity} from "react-native";
import axios from "../utils/axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {NavigationProp, RouteProp} from "@react-navigation/native";
import call_kw from "../api/call_kw";
import {SelectList, SelectListProps} from "react-native-dropdown-select-list"
function HomeScreen({navigation, route}: { navigation: NavigationProp<any>, route: RouteProp<any> }) {
    const [name, setName] = useState<string | null>(null);


    useEffect(() => {
        ;(async () => {
            const name = await AsyncStorage.getItem('name');
            setName(name);
        })();
    }, [])

    const logout = () => {
        AsyncStorage.removeItem('token');
        AsyncStorage.removeItem('user_id');
        axios.post('/session/logout', {
            "params": {}
        }).then(async res => {
            navigation.navigate('Login');
        })
    }

    const scan = () => {
        navigation.navigate('Modal');
    }


    return (
        <SafeAreaView style={[styles.area]}>
            <View style={[styles.container]}>
                <Text>{route.params?.message}</Text>
                <Text>{name}</Text>
                <TouchableOpacity style={[styles.scanqr]} onPress={() => scan()}><Text style={[styles.scanrqText]}>QR
                    OKUT</Text></TouchableOpacity>
                <TouchableOpacity style={[styles.dayOffButton]} onPress={() => navigation.navigate('DayOff')}>
                    <Text style={[styles.dayOffButtonText]}>IZIN FORMU</Text>
                </TouchableOpacity>
                <TouchableOpacity style={[styles.logoutButton]} onPress={() => logout()}><Text
                    style={[styles.logoutButtonText]}>ÇIKIŞ YAP</Text></TouchableOpacity>

            </View>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    area: {
        flex: 1,
    },
    container: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    scanqr: {
        backgroundColor: "#49BBEA",
        paddingVertical: 20,
        marginTop: 50,
        borderRadius: 10,
        width: 300,
        flexDirection: "row",
        justifyContent: "center",
    },
    scanrqText: {
        color: "white",
        fontSize: 20,
        fontWeight: "bold",
        letterSpacing: 2,
    },
    logoutButton: {
        backgroundColor: "red",
        paddingVertical: 20,
        marginTop: 50,
        borderRadius: 10,
        width: 300,
        flexDirection: "row",
        justifyContent: "center",
    },
    logoutButtonText: {
        color: "white",
        fontSize: 20,
        fontWeight: "bold",
        letterSpacing: 2,
    },
    dayOffButton: {
        backgroundColor: "#49BBEA",
        paddingVertical: 20,
        marginTop: 50,
        borderRadius: 10,
        width: 300,
        flexDirection: "row",
        justifyContent: "center",
    },
    dayOffButtonText: {
        color: "white",
        fontSize: 20,
        fontWeight: "bold",
        letterSpacing: 2,
    }
})
export default HomeScreen;
