/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import {FontAwesome} from '@expo/vector-icons';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {NavigationContainer, DefaultTheme, DarkTheme} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import * as React from 'react';
import {ColorSchemeName, Pressable} from 'react-native';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import QR from '../screens/QR';
import NotFoundScreen from '../screens/NotFoundScreen';
import TabOneScreen from '../screens/TabOneScreen';
import {RootStackParamList, RootTabParamList, RootTabScreenProps} from '../types';
import LinkingConfiguration from './LinkingConfiguration';
import LoginScreen from "../screens/LoginScreen";
import TabTwoScreen from "../screens/TabTwoScreen";
import HomeScreen from "../screens/HomeScreen";
import OnboardScreen from "../screens/OnboardScreen";
import RoleScreen from "../screens/RoleScreen";
import ConsentFormScreen from "../screens/ConsentFormScreen";

export default function Navigation({colorScheme}: { colorScheme: ColorSchemeName }) {
    return (
        <NavigationContainer
            linking={LinkingConfiguration}
            // theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}
        >
            <RootNavigator/>
        </NavigationContainer>
    );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator();

function RootNavigator() {
    return (
        <Stack.Navigator screenOptions={{
            animation: "simple_push"
        }}>
            {/*<Stack.Screen name="Root" component={OnboardScreen} options={{headerShown: false}}/>*/}
            <Stack.Screen name="Login" component={LoginScreen} options={{headerShown: false}}/>
            <Stack.Screen name="RoleScreen" component={RoleScreen} options={{headerShown: false}}/>
            <Stack.Screen name="HomeScreen" component={HomeScreen} options={{headerShown: false}}/>

            <Stack.Screen name="TabOne" component={TabOneScreen} options={{headerShown: false}}/>
            <Stack.Screen name="TabTwo" component={TabTwoScreen} options={{headerShown: false}}/>
            <Stack.Screen name="NotFound" component={NotFoundScreen} options={{title: 'Oops!'}}/>
            <Stack.Screen name="DayOff" component={ConsentFormScreen} options={{
                title: '',
                headerBackTitle: "Geri",
            }}/>
            <Stack.Group screenOptions={{presentation: 'containedModal'}}>
                <Stack.Screen name="Modal" options={({navigation}) => (
                    {
                        title: "QR", headerLeft: () => (
                            <Pressable
                                style={({pressed}) => ({
                                    opacity: pressed ? 0.5 : 1,
                                })} onPress={() => navigation.pop()}>
                                <FontAwesome
                                    name="arrow-left"
                                    size={25}
                                    style={{marginRight: 15}}
                                />
                            </Pressable>
                        )
                    }
                )} component={QR}/>
            </Stack.Group>
        </Stack.Navigator>
    );
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<RootTabParamList>();

function BottomTabNavigator() {
    const colorScheme = useColorScheme();

    return (
        <BottomTab.Navigator
            screenOptions={{
                tabBarActiveTintColor: Colors[colorScheme].tint,
            }}>
        </BottomTab.Navigator>
    );
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: {
    name: React.ComponentProps<typeof FontAwesome>['name'];
    color: string;
}) {
    return <FontAwesome size={30} style={{marginBottom: -3}} {...props} />;
}
