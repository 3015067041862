import React, {useEffect} from 'react';
import {View, StyleSheet, Image, Text, Animated} from "react-native";

function SplashScreen() {
    const [fadeAnim] = React.useState(new Animated.Value(200));
    const [fadeAnim2] = React.useState(new Animated.Value(200));

    useEffect(() => {
        Animated.loop(
            Animated.stagger(300, [
                Animated.timing(fadeAnim, {
                    toValue: 300,
                    duration: 2000,
                    useNativeDriver: false
                }),
                Animated.timing(fadeAnim2, {
                    toValue: 350,
                    duration: 2000,
                    useNativeDriver: false
                }),
            ])
        ).start()

    }, [])
    return (
        <View style={[styles.container]}>
            {/*<Image style={[styles.icon]} source={{uri: ""}}></Image>*/}
            <View style={[styles.icon]}><Text style={{fontSize: 30, color: "#567DF4", fontWeight: "bold"}}>HR</Text></View>
            <Animated.View style={[styles.icon, {width: fadeAnim, height: fadeAnim, borderRadius: fadeAnim, opacity: fadeAnim.interpolate({
                inputRange: [200, 300],
                outputRange: [0.5, 0]
                })}]}></Animated.View>
            <Animated.View style={[styles.icon, {width: fadeAnim2, height: fadeAnim2, borderRadius: fadeAnim2, opacity: fadeAnim2.interpolate({
                inputRange: [200, 350],
                outputRange: [0.5, 0]
                })}]}></Animated.View>
        </View>
    );
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#567DF4',
    },
    icon: {
        width: 200,
        height: 200,
        backgroundColor: '#fff',
        borderRadius: 100,
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
    }
})
export default SplashScreen;
