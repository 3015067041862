import axios from "../utils/axios";


export default async function call_kw(model, method, args, kwargs, context) {
    const config = {
        url: `/dataset/call_kw`,
        headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"},
        method: "POST",
        data: {
            params: {
                model,
                method,
                args: args,
                kwargs: {
                    context: {
                        lang: "tr_TR",
                        tz: "Europe/Istanbul",
                        ...context
                    },
                    ...kwargs,
                },
            }
        },
    };

    const res = (await axios(config)).data;

    return res;
}
