import {Animated, StyleSheet, TouchableOpacity, Text, View, Easing} from 'react-native';

import {Card, Avatar} from "react-native-paper";
import {SafeAreaView} from "react-native-safe-area-context";

export default function TabOneScreen({navigation}) {
    function nextPage(selected) {
        navigation.navigate("TabTwo", {selected});
    }
    return (
        <SafeAreaView style={{flex: 1}}>
            <View style={[styles.container]}>
                <Animated.View style={[{
                    height: '55%',
                    width: '90%',
                }]}>
                    <Card style={[styles.card]}>
                        <Card.Title style={[styles.titleWrapper]} titleStyle={[styles.titleStyle]}
                                    subtitleStyle={[styles.subtitleStyle]}
                                    title="How are you today?" subtitle={"4TH DAILY CHECK-IN"}/>
                        <Card.Content style={[styles.content]}>
                            <TouchableOpacity onPress={() => nextPage(1)}>
                                <View style={[styles.avatarWrapper]}>
                                    <Avatar.Image style={[styles.avatar]}
                                                  source={require("../assets/images/great.png")}></Avatar.Image>
                                    <Text style={{marginTop: -15}}>Great</Text>
                                </View>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => nextPage(2)}>
                                <View style={[styles.avatarWrapper]}>
                                    <Avatar.Image style={[styles.avatar]}
                                                  source={require("../assets/images/good.png")}></Avatar.Image>
                                    <Text style={{marginTop: -15}}>Good</Text>
                                </View>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => nextPage(3)}>
                                <View style={[styles.avatarWrapper]}>
                                    <Avatar.Image style={[styles.avatar]}
                                                  source={require("../assets/images/okay.png")}></Avatar.Image>
                                    <Text style={{marginTop: -15}}>Okay</Text>
                                </View>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => nextPage(4)}>
                                <View style={[styles.avatarWrapper]}>
                                    <Avatar.Image style={[styles.avatar]}
                                                  source={require("../assets/images/bad.png")}></Avatar.Image>
                                    <Text style={{marginTop: -15}}>Bad</Text>
                                </View>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => nextPage(5)}>

                                <View style={[styles.avatarWrapper]}>
                                    <Avatar.Image style={[styles.avatar]}
                                                  source={require("../assets/images/terrible.png")}></Avatar.Image>
                                    <Text style={{marginTop: -15}}>Terrible</Text>
                                </View>
                            </TouchableOpacity>
                        </Card.Content>
                        <Card.Actions style={{justifyContent: 'center', marginTop: 20}}>
                            <TouchableOpacity onPress={() => nextPage(0)}><Text style={{opacity: 0.5}}>Skip check-in</Text></TouchableOpacity>
                        </Card.Actions>
                    </Card>
                </Animated.View>
            </View>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#F1D8CE',
        alignItems: 'flex-end',
        justifyContent: 'center',
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    card: {
        borderRadius: 25,
        paddingVertical: 25,
    },
    titleWrapper: {
        paddingVertical: 20,
    },
    titleStyle: {
        alignSelf: 'center',
    },
    subtitleStyle: {
        alignSelf: 'center',
    },
    content: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
    },
    avatarWrapper: {
        alignItems: 'center',
    },
    avatar: {
        marginHorizontal: 20,
        marginVertical: 20,
    }
});
