import React from 'react';
import {SafeAreaView, View, StyleSheet, Text, Image, TouchableOpacity} from "react-native";
import Dimensions from "../constants/Layout";
import {LinearGradient} from "expo-linear-gradient";
import {Avatar} from "react-native-paper"
import {Entypo, MaterialCommunityIcons, MaterialIcons} from "@expo/vector-icons";

const {width, height} = Dimensions.window;

function RoleScreen(props) {
    return (
        <SafeAreaView style={{flex: 1}}>
            <LinearGradient
                colors={['#FFFDFE', '#F3E7F8', '#c7c6f8']} style={styles.container}>
                <View style={[styles.container]}>
                    <View style={[styles.avatarWrapper]}>
                        <Avatar.Image size={90} source={require('../assets/illustrations/profile1.png')}
                                      style={[styles.avatar, {
                                          transform: [{
                                              translateY: -30,
                                          }]
                                      }]}></Avatar.Image>
                        <Avatar.Image size={120} source={require('../assets/illustrations/profile2.png')}
                                      style={[styles.avatar, {
                                          transform: [{
                                              translateX: 70,
                                          }]
                                      }]}></Avatar.Image>
                        <Avatar.Image size={100} source={require('../assets/illustrations/profile3.png')}
                                      style={[styles.avatar, {
                                          transform: [{
                                              translateX: -70,
                                          }]
                                      }]}></Avatar.Image>
                        <Avatar.Image size={130} source={require('../assets/illustrations/profile4.png')}
                                      style={[styles.avatar, {
                                          transform: [{
                                              translateY: 70,
                                          }]
                                      }]}></Avatar.Image>
                    </View>
                    <View style={{
                        flex: 1,
                    }}>
                        <Text style={[styles.title]}>Select Your Role</Text>
                        <View style={{
                            flexDirection: "row",
                            backgroundColor: "#fff",
                            borderRadius: 10,
                            padding: 20,
                            width: width - 20,
                            borderWidth: 1,
                            borderColor: "#312f79",
                            alignItems: "center",
                            marginBottom: 20
                        }}>
                            <MaterialCommunityIcons name="office-building" size={40} color="black"/>
                            <View style={{
                                flex: 1,
                                marginLeft: 10,
                            }}>
                                <Text style={{fontSize: 18, fontWeight: "500", marginBottom: 5}}>Business Owner / Admin
                                    / HR</Text>
                                <Text style={{fontSize: 13, fontWeight: "400", color: "#8E8E93"}}>Register your company
                                    & start attendance</Text>
                            </View>
                        </View>
                        <TouchableOpacity onPress={() => {
                            props.navigation.navigate("Login")
                        }}>
                            <View style={{
                                flexDirection: "row",
                                backgroundColor: "#fff",
                                borderRadius: 10,
                                padding: 20,
                                width: width - 20,
                                borderWidth: 1,
                                borderColor: "#312f79",
                                alignItems: "center",
                            }}>
                                <MaterialIcons name="emoji-people" size={40} color="black"/>
                                <View style={{
                                    flex: 1,
                                    marginLeft: 10,
                                }}>
                                    <Text style={{fontSize: 18, fontWeight: "500", marginBottom: 5}}>Employee</Text>
                                    <Text style={{fontSize: 13, fontWeight: "400", color: "#8E8E93"}}>Register and
                                        marking your attendance</Text>
                                </View>
                            </View>
                        </TouchableOpacity>
                    </View>
                </View>
            </LinearGradient>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    avatarWrapper: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
    },
    avatar: {
        position: "absolute",
    },
    title: {
        textAlign: "center",
        fontSize: 30,
        fontWeight: "500",
        color: "#312f79",
        marginBottom: 20

    }
});
export default RoleScreen;
