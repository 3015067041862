import {
    SafeAreaView,
    TextInput,
    StyleSheet, Text, TouchableOpacity, View, Switch, Image,
} from "react-native";
import React, {useEffect, useState} from "react";
import axios, {axios_constants} from "../utils/axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {AntDesign} from "@expo/vector-icons";
import call_kw from "../api/call_kw";

function LoginScreen({navigation}) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [saveme, setSaveme] = useState(false);

    const login = () => {
        axios.post('/session/authenticate', {
            params: {
                "login": username,
                "password": password,
                "db": axios_constants.db
            }
        }).then(res => {
            call_kw('hr.employee', 'search_read', [[['user_id', '=', res.data.result?.uid]]], {
                fields: ['id'],
            }).then(res => {
                AsyncStorage.setItem('employee_id', `${res?.result[0]?.id}`);
            })

            if (res.data.result?.uid) {
                AsyncStorage.setItem('token', res.headers["set-cookie"][0].split(";")[0]);
                AsyncStorage.setItem('user_id', `${res.data.result?.uid}`);
                AsyncStorage.setItem('company_id', `${res.data.result?.company_id}`);
                AsyncStorage.setItem('name', res.data.result?.name);

                navigation.navigate('HomeScreen');
            }
        }).catch(err => {
            console.log(err);
        })
    }
    useEffect(() => {
        axios.post('/session/get_session_info', {
            "params": {}
        }).then(res => {
            call_kw('hr.employee', 'search_read', [[['user_id', '=', res.data.result?.uid]]], {
                fields: ['id'],
            }).then(res => {
                AsyncStorage.setItem('employee_id', `${res?.result[0]?.id}`);
            })

            if (res.data.result?.uid) {
                AsyncStorage.setItem('user_id', `${res.data?.result?.uid}`);
                AsyncStorage.setItem('company_id', `${res.data.result?.company_id}`);
                AsyncStorage.setItem('name', res.data.result?.name);
                navigation.navigate('HomeScreen');
            }
        }).catch(err => {
            console.log(err)
        })

    }, []);


    return (
        <SafeAreaView style={[styles.area]}>
            <View style={[styles.container]}>
                <View style={[styles.header]}>
                    <AntDesign name="arrowleft" size={30} color="white"/>
                    <Text style={[styles.headerText]}>Giris Yap</Text>
                </View>
                <View style={[styles.bottomSheet]}>

                    <TextInput autoComplete={"email"} style={[styles.input]} placeholderTextColor={"#9D9D9D"}
                               placeholder={"Email"} onChange={e => setUsername(e.nativeEvent.text)}/>
                    <TextInput secureTextEntry={true} textContentType={"password"} style={[styles.input]}
                               placeholderTextColor={"#9D9D9D"} placeholder={"Şifre"}
                               onChange={e => setPassword(e.nativeEvent.text)}/>

                    <View style={{flexDirection: 'row', alignItems: 'center', marginVertical: 10}}>
                        <Text>Beni Hatirla</Text>
                        <Switch onChange={() => setSaveme(!saveme)} value={saveme}></Switch>
                    </View>

                    <TouchableOpacity style={[styles.loginButton]} onPress={() => login()}>
                        <Text style={[styles.loginText]}>Giriş Yap</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    area: {
        flex: 1,
        backgroundColor: '#E21836',
    },
    container: {
        flex: 1,
    },
    header: {
        flexDirection: "row",
        alignItems: "center",
        paddingHorizontal: 20,
        paddingBottom: 50,
        paddingTop: 20,

    },
    headerText: {
        fontSize: 20,
        color: "white",
        fontWeight: "bold",
        marginLeft: 20,
    },
    bottomSheet: {
        flex: 1,
        width: "100%",
        alignItems: "center",
        backgroundColor: "white",
        borderTopLeftRadius: 40,
        borderTopRightRadius: 40,
        paddingVertical: 20,
    },
    input: {
        borderWidth: 1,
        backgroundColor: "white",
        borderColor: "#c7c7c7",
        paddingHorizontal: 10,
        paddingVertical: 15,
        margin: 10,
        borderRadius: 5,
        width: "80%"
    },
    loginButton: {
        backgroundColor: "#E21836",
        paddingHorizontal: 10,
        paddingVertical: 15,
        margin: 10,
        borderRadius: 5,
        width: "80%",
        alignItems: 'center',
    },
    loginText: {
        color: "white",
        fontSize: 20,
        fontWeight: "500",

    }
});
export default LoginScreen;
