import React, {useCallback, useEffect, useRef, useState} from 'react';
import {View, StyleSheet, TextInput, Text, TouchableOpacity, Platform, DatePickerIOS, Alert} from "react-native";
import {ScreenProps} from "../types";
import {SelectList, SelectListProps} from "react-native-dropdown-select-list";
import call_kw from "../api/call_kw";
import {Card} from "react-native-paper";
import Odoo from "react-native-odoo-promise-based"
import moment from "moment";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {DateTimePickerAndroid} from "@react-native-community/datetimepicker";
import BottomSheet, {BottomSheetModal, BottomSheetModalProvider} from "@gorhom/bottom-sheet";

function ConsentFormScreen({navigation, route}: ScreenProps) {
    const bottomSheetModalRef = useRef<BottomSheetModal>(null);
    const [value, setValue] = useState<SelectListProps | null>(null);
    const [name, setName] = useState<string | undefined>("");
    const [data, setData] = useState<object[]>([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    useEffect(() => {

        call_kw('hr.leave.type', 'search_read', []).then(res => {
            let data = res.result;
            data = data.sort((a, b) => a.id - b.id).map((item: any) => {
                return {key: item.id, value: item.name}
            })
            if (data.length > 0) {
                setData(data)
            }
        })
    }, [])


    const changeDate = (dateType: string) => {
        if (Platform.OS === 'android') {
            DateTimePickerAndroid.open({
                value: startDate,
                mode: 'date',
                minimumDate: new Date(),
                onChange: (event, date) => {
                    if (event.type === 'set') {
                        if (dateType === 'start') {
                            setStartDate(date);
                        }
                        if (dateType === 'end') {
                            setEndDate(date);
                        }
                    }
                }
            })
        }

    }
    const submit = async () => {
        const uid = await AsyncStorage.getItem('user_id');
        const company_id = await AsyncStorage.getItem('company_id');
        const employee_id = await AsyncStorage.getItem('employee_id');

        call_kw('hr.leave', 'create',
            [
                {
                    "name": name,
                    "employee_id": parseInt(employee_id),
                    "holiday_status_id": parseInt(value),
                    "create_uid": uid,
                    "mode_company_id": parseInt(company_id),
                    "date_from": moment(startDate).format('Y-MM-DD'),
                    "date_to": moment(endDate).format('Y-MM-DD'),
                }
            ]).then(res => {
            if(res?.error){
                Alert.alert("Hata", res.error.data.arguments[0])
            }
            if(res?.result){
                navigation.navigate('HomeScreen', {message: "İzin formu başarıyla gönderildi."});
            }
        })

    }

    const snapPoints = React.useMemo(() => ['30%'], []);
    const handlePresentModalPress = useCallback(() => {
        bottomSheetModalRef.current?.present();
    }, []);

    return (
        <BottomSheetModalProvider>
            <View style={[styles.container]}>
                <Card style={{
                    padding: 20,
                }}>
                    <Card.Title title={"İzin Formu"} titleStyle={{
                        fontSize: 20,
                        fontWeight: 'bold',
                    }}/>
                    <View style={[styles.timepickerWrapper]}>
                        <TextInput placeholder="Aciklama" value={name} onChangeText={setName}
                                   placeholderTextColor="#acacac"
                                   style={[styles.description]}/>
                        <Text style={{
                            fontSize: 16,
                            marginTop: 20
                        }}>Başlangıç</Text>
                        <TouchableOpacity style={{
                            marginTop: 10,
                            width: 300,
                            height: 40,
                            borderColor: '#acacac',
                            borderWidth: 1,
                            borderRadius: 10,
                            paddingHorizontal: 10,
                            justifyContent: 'center',
                        }} onPress={e => {
                            handlePresentModalPress();
                            changeDate('start')
                        }}>
                            <Text style={{
                                fontSize: 16,
                            }}>{moment(startDate).format('Y-MM-DD')}</Text>
                        </TouchableOpacity>
                        <Text style={{
                            fontSize: 16,
                            marginTop: 20
                        }}>Bitiş</Text>
                        <TouchableOpacity style={{
                            marginTop: 10,
                            width: 300,
                            height: 40,
                            borderColor: '#acacac',
                            borderWidth: 1,
                            borderRadius: 10,
                            paddingHorizontal: 10,
                            justifyContent: 'center',
                        }} onPress={e => changeDate('end')}>
                            <Text style={{
                                fontSize: 16,
                            }}>{moment(endDate).format('Y-MM-DD')}</Text>
                        </TouchableOpacity>
                    </View>

                    <SelectList boxStyles={{
                        marginTop: 20,
                        width: 300,
                        borderColor: '#acacac',
                    }} data={data} search={false} placeholder="İzin türü" setSelected={(val: any) => setValue(val)}
                                save={value}/>

                    <TouchableOpacity style={[styles.sendButton]} onPress={submit}>
                        <Text style={[styles.sendButtonText]}>Gönder</Text>
                    </TouchableOpacity>
                </Card>
                {Platform.OS === 'ios' && (
                    <BottomSheetModal ref={bottomSheetModalRef} snapPoints={snapPoints} index={0}
                                      enablePanDownToClose={true}>
                        <DatePickerIOS onDateChange={setStartDate} date={startDate} mode="date" style={{
                            width: "100%",
                            backgroundColor: '#acacac',
                            flex: 1
                        }} minimumDate={new Date()}/>
                    </BottomSheetModal>
                )}
            </View>
        </BottomSheetModalProvider>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: "linear-gradient(to bottom, #00bfff, #ff00ff)",
    },
    description: {
        width: 300,
        height: 40,
        borderColor: '#acacac',
        borderWidth: 1,
        borderRadius: 10,
        paddingHorizontal: 10,
    },
    timepickerWrapper: {
        flexDirection: "column",
        marginTop: 20,
        width: 300,
        paddingVertical: 10,
    },
    datePickerStyle: {
        width: "100%",
        marginTop: 10,
    },
    sendButton: {
        backgroundColor: "#49BBEA",
        paddingVertical: 20,
        marginTop: 50,
        borderRadius: 10,
        width: 300,
        flexDirection: "row",
        justifyContent: "center",
    },
    sendButtonText: {
        color: "white",
        fontSize: 20,
        fontWeight: "bold",
        letterSpacing: 2,
    }
});
export default ConsentFormScreen;
