import React from 'react';
import {StatusBar} from 'expo-status-bar';
import {Platform, StyleSheet, View, Text} from 'react-native';
import {BarCodeScanner} from "expo-barcode-scanner";
import {useEffect, useState} from "react";
import * as Location from "expo-location";
import moment from "moment";
import axios from "../utils/axios";
import axiosDefault from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Constants from 'expo-constants';
import {LocationObject} from "expo-location";
import {ScreenProps} from "../types";
import call_kw from "../api/call_kw";


export default function QR({navigation, route}: ScreenProps) {
    const [hasPermission, setHasPermission] = useState<boolean | null>(null);
    const [location, setLocation] = useState<LocationObject | null>(null);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const [uname, setUsername] = useState<string | null>(null);
    const [uid, setUid] = useState<string | null>(null);
    const [barcodeScanned, setBarcodeScanned] = useState<boolean>(false);


    const getLocation = async () => {
        let {status} = await Location.requestForegroundPermissionsAsync();
        if (status !== 'granted') {
            setErrorMsg('Permission to access location was denied');
            return;
        }
        const loc = await Location.getCurrentPositionAsync({
            accuracy: Location.Accuracy.Lowest,
        })
        setLocation(loc);
    }

    useEffect(() => {
        (async () => {
            const user_id = await AsyncStorage.getItem('user_id')
            setUsername(user_id);
            const name = await AsyncStorage.getItem('name');
            setUid(name);
        })();


        const getBarCodeScannerPermissions = async () => {
            const {status} = await BarCodeScanner.requestPermissionsAsync();
            setHasPermission(status === "granted");
        };

        getBarCodeScannerPermissions();
        getLocation()

        if (!location?.coords) {
            setErrorMsg("Lutfen bekleyiniz..")
            getLocation()
            setTimeout(() => {
                setErrorMsg(null)
            }, 2000)
        }

    }, []);

    const sendMail = async (message: string) => {
        axiosDefault.post("https://api.sendgrid.com/v3/mail/send", {
            personalizations: [{
                to: [
                    {email: "ik@newbalance.com.tr"},
                    {email: "oaydin@newbalance.com.tr"},
                    {email: "bt@newbalance.com.tr"},
                ],
            }],
            from: {
                email: "kazim@erpexecutives.com"
            },
            cc: [
                {email: "zeynelagca@gmail.com"}

            ],
            subject: "HR - Barcode Scan",
            content: [{
                type: "text/plain", value: message
            }]
        }, {
            headers: {
                Authorization: `Bearer ${Constants?.expoConfig?.extra.SENDGRID_API_KEY}`,
                "Content-Type": "application/json"
            }
        }).then(res => {
            console.log("Mail Gonderildi")
        }).catch(err => {
            console.log(err)
        })
    }
    const handleBarCodeScanned = async ({data}: any) => {
        const jsonData = JSON.parse(data.replace(/\'/g, `"`))
        let workplacecode = null;
        let qrcode_type: any = null;

        if (jsonData.length > 0) {
            workplacecode = jsonData[0].workplacecode_id;
            qrcode_type = jsonData[0].qrcode_type;
        }


        setBarcodeScanned(true);
        call_kw('qr.attendance', 'create', [
            {
                "attendance_date": moment().format('Y-MM-DD'),
                "qr_code_type": qrcode_type,
                "create_uid": uname,
                "latitude": location?.coords?.latitude || 2,
                "longitude": location?.coords?.longitude || 2,
                "work_place_code_id": workplacecode,
            }
        ], {}, {
            "uid": uname,
        }).then(res => {
            console.log(res)
            sendMail(`Barkod okundu ${uid} tarafindan ${moment().format('Y-MM-D HH:mm')} tarihinde ${qrcode_type === '11' ? 'giris Yapildi' : "cikis yapildi"}`)
        }).catch(err => {
            console.log(err)
        })
        if (qrcode_type === '10') {
            sendMail(`Barkod okundu ${uid} tarafindan ${moment().format('Y-MM-D HH:mm')} tarihinde magaza acilisi yapildi`)
            navigation.navigate({
                name: 'HomeScreen',
                params: {
                    message: 'QR kodunuz başarıyla okundu. Magaza acilisi yapildi!'
                },
                merge: true
            });
        }
        if (qrcode_type === '11') {
            navigation.navigate({
                name: 'HomeScreen',
                params: {
                    message: 'QR kodunuz başarıyla okundu. Ve giris yapildi!'
                },
                merge: true
            });
        }
        if (qrcode_type === '12') {
            navigation.navigate({
                name: 'HomeScreen',
                params: {
                    message: 'QR kodunuz başarıyla okundu. Ve cikis yapildi!'
                },
                merge: true
            });
        }

    };

    if (hasPermission === null) {
        return <Text>Requesting for camera permission</Text>;
    }
    if (hasPermission === false) {
        return <Text>No access to camera</Text>;
    }


    return (
        <View style={styles.container}>
            {errorMsg ? <Text>{errorMsg}</Text> : <BarCodeScanner
                onBarCodeScanned={barcodeScanned ? undefined : handleBarCodeScanned}
                style={StyleSheet.absoluteFillObject}
                barCodeTypes={[BarCodeScanner.Constants.BarCodeType.qr]}
            />}

            <StatusBar style={Platform.OS === 'ios' ? 'dark' : 'auto'}/>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: "linear-gradient(to bottom, #00bfff, #ff00ff)",
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    separator: {
        marginVertical: 30,
        height: 1,
        width: '80%',
    },
});
